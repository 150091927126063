a {
  color: inherit;
  text-decoration: none;
}

body {
  background: #f5f5f5;
}

#AppRoot.Darkmode {
  background: #222;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
